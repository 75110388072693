<template>
  <div class="flex flex-col space-y-4 my-4">
    <ModalListFile
      @closeModal="
        () => {
          showModal = false
        }
      "
      :showModal="showModal"
      :current_docs_modal="current_docs_modal"
      :current_title_modal="current_title_modal"
      :current_action_title_modal="current_action_title_modal"
    ></ModalListFile>
    <h2 class="text-lg tracking-wide font-main text-gray-800 font-bold">
      Activité du Promoteur
    </h2>
    <div v-if="activitiesList.length >= 0">
      <div
        class="grid grid-cols-9 sm:grid-cols-3 md:grid-cols-7 lg:grid-cols-7 gap-x-2 pr-7 pl-4 text-promy-gray-570 text-xs mb-4"
      >
        <HistoriqueSortBy
          ref="adresse"
          displayText="Adresse"
          :SortBy="sortColumns.adresse"
          :resetSortIcon="resetSortIcon"
          class="col-span-2"
          expand="true"
          @sortEvent="sortList"
        />
        <HistoriqueSortBy
          ref="nombre_lots"
          displayText="Nb lot"
          :SortBy="sortColumns.nombre_lots"
          :resetSortIcon="resetSortIcon"
          @sortEvent="sortList"
          class="sm:hidden"
        />
        <HistoriqueSortBy
          ref="date_depot"
          displayText="Date dépôt"
          :SortBy="sortColumns.date_depot"
          :resetSortIcon="resetSortIcon"
          @sortEvent="sortList"
          class="sm:hidden lg:hidden"
        />
        <HistoriqueSortBy
          ref="date_fin_commercialisation"
          displayText="Date de fin"
          :SortBy="sortColumns.date_fin_commercialisation"
          :resetSortIcon="resetSortIcon"
          @sortEvent="sortList"
          class="sm:hidden lg:hidden"
        />
        <HistoriqueSortBy
          ref="prix_moy_m2_nbre_lots"
          displayText="Prix Moy m² / lot"
          :SortBy="sortColumns.prix_moy_m2_nbre_lots"
          :resetSortIcon="resetSortIcon"
          @sortEvent="sortList"
          class="sm:hidden"
        />
        <HistoriqueSortBy
          ref="chiffre_affaire"
          displayText="Chiffre d'affaire"
          :SortBy="sortColumns.chiffre_affaire"
          :resetSortIcon="resetSortIcon"
          class="col-span-2 sm:hidden lg:col-span-2"
          @sortEvent="sortList"
        />
      </div>
      <accordion class="max-h-144 overflow-y-scroll">
        <accordion-item
          class="shadow-box mb-4"
          v-for="(prog, index) in activitiesList"
          :key="index"
          :indice="index"
        >
          <template slot="accordion-trigger">
            <a
              class="w-full py-4 cursor-pointer items-center"
              @click="activingAccordion(index)"
            >
              <div
                class="pr-7 pl-4 sm:px-3 grid grid-cols-9 sm:grid-cols-3 md:grid-cols-7 lg:grid-cols-7 gap-x-2 items-center text-promy-gray-700 text-sm"
              >
                <div class="flex items-start col-span-2">
                  <div class="pl-8">
                    {{ prog.adresse }}
                  </div>
                </div>
                <div class="pl-3 sm:hidden">
                  {{ prog.nombre_lots }}
                </div>
                <div class="pl-3 md:hidden lg:hidden">
                  {{ prog.date_depot }}
                </div>
                <div class="pl-3 md:hidden lg:hidden">
                  {{ prog.date_fin_commercialisation }}
                </div>
                <div class="pl-3 sm:hidden">
                  {{ prog.prix_moy_m2_nbre_lots | formatEUR(2) }}
                </div>
                <div class="pl-3 col-span-2 sm:hidden lg:col-span-2">
                  {{ prog.chiffre_affaire | formatEUR(2) }}
                </div>
                <div
                  class="w-max flex md:flex-wrap space-x-2 md:space-x-0 md:space-y-1 justify-end sm:justify-between items-center md:w-full md:flex-col md:items-end"
                >
                  <div
                    class="h-7 w-7 md:w-14 rounded-full flex justify-center items-center transition-all"
                    :class="{
                      'bg-promy-purple-400 text-white':
                        active_accordion === index,
                    }"
                  >
                    <i
                      :class="[
                        !active_accordion
                          ? 'fas fa-chevron-up'
                          : 'fas fa-chevron-down',
                      ]"
                    />
                  </div>
                </div>
              </div> </a
          ></template>
          <template slot="accordion-content">
            <div class="flex w-full px-5 flex-col">
              <hr class="w-full h-full" />
              <div class="flex w-full space-x-4 my-4 sm:flex-col sm:space-x-0">
                <div class="flex flex-col space-y-8">
                  <div class="flex space-x-4 mb-4 sm:flex-col sm:space-x-0">
                    <div class="flex w-96 sm:w-full lg:w-1/2 flex-col sm:mb-4">
                      <template v-if="prog.images && prog.images.length">
                        <!-- <carousel :per-page="1" :mouse-drag="true">
                          <slide
                            v-for="(image, index) in prog.images"
                            :key="index"
                          > -->
                        <div
                          class="bg-no-repeat bg-cover bg-center rounded-box w-full h-50"
                          :style="`background-image: url(${
                            $base_file + prog.images[0]
                          })`"
                        ></div>
                        <!-- </slide>
                        </carousel> -->
                      </template>
                      <div
                        v-else
                        class="bg-no-repeat bg-cover bg-center rounded-box w-full h-50"
                        :style="'background-image: url(/images/programme/default_programme.png)'"
                      ></div>
                    </div>
                    <div
                      class="w-1/2 sm:w-full grid grid-cols-3 lg:grid-cols-2 rowGap14 gap-2"
                    >
                      <div class="flex flex-col space-y-2">
                        <span class="h-3 text-promy-gray-400 text-sm"
                          >Nb lots</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.nombre_lots || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-3 text-promy-gray-400 text-sm"
                          >Typologie</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.nombre_pieces || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Date Dépot de permis de construire</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.date_depot || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-3 text-promy-gray-400 text-sm"
                          >Type</span
                        >
                        <span class="text-sm text-promy-gray-700 capitalize">
                          {{ prog.typologie || '-' }}
                        </span>
                      </div>

                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Type de stationnement</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.type_stationnements || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Date Obtention de permis de construire</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.date_obtention || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Prix moyen/m2 nombre de lots HT</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.prix_moy_m2_nbre_lots || '-' | formatEUR(2) }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Prix moyen/m2 surface habitable HT</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.prix_moy_m2_surface_hab || '-' | formatEUR }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Date de début de commercialisation</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.date_debut_commercialisation || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-3 text-promy-gray-400 text-sm"
                          >Stationnement</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.stationnement || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-3 text-promy-gray-400 text-sm"
                          >Fiscalité</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.fiscalite || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Date de fin de commercialisation</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.date_fin_commercialisation || '-' }}
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Taille parcelle</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.taille_parcelle || '-' }}m²
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Surface habitable totale</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.surface_plancher || '-' }}m²
                        </span>
                      </div>
                      <div class="flex flex-col space-y-2">
                        <span class="h-max text-promy-gray-400 text-sm"
                          >Date de livraison</span
                        >
                        <span class="text-sm text-promy-gray-700">
                          {{ prog.date_livraison || '-' }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="sm:w-full grid grid-cols-3 lg:grid-cols-2 gap-2 rowGap35"
                  >
                    <div class="flex flex-col space-y-2">
                      <span class="h-3 text-promy-gray-400 text-sm">Nom</span>
                      <span class="text-sm text-promy-gray-700">
                        {{ prog.name || '-' }}
                      </span>
                    </div>
                    <div class="flex flex-col space-y-2">
                      <span class="h-3 text-promy-gray-400 text-sm"
                        >Adresse</span
                      >
                      <span class="text-sm text-promy-gray-700">
                        {{ prog.adresse || '-' }}
                      </span>
                    </div>
                    <div class="flex flex-col space-y-2">
                      <span class="h-3 text-promy-gray-400 text-sm"
                        >Description</span
                      >
                      <span class="text-sm text-promy-gray-700">
                        {{ prog.description || '-' }}
                      </span>
                    </div>
                    <div class="flex flex-col space-y-2">
                      <span class="h-3 text-promy-gray-400 text-sm"
                        >Note interne</span
                      >
                      <span class="text-sm text-promy-gray-700">
                        {{ prog.note_intern || '-' }}
                      </span>
                    </div>
                    <div class="flex flex-col space-y-2">
                      <span class="h-max text-promy-gray-400 text-sm"
                        >Responsable projet</span
                      >
                      <span class="text-sm text-promy-gray-700">
                        {{ prog.responsable_projet || '-' }}
                      </span>
                    </div>
                    <div class="flex flex-col space-y-2">
                      <span class="h-3 text-promy-gray-400 text-sm">Type</span>
                      <span class="text-sm text-promy-gray-700">
                        {{ prog.typologie || '-' }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="w-1/4 sm:w-full flex flex-col justify-between sm:mt-2 sm:space-y-2"
                >
                  <div class="flex flex-col space-y-2 items-end ml-auto w-fit">
                    <button
                      v-if="prog.list_docs && prog.list_docs.length"
                      @click="setModalInfos(prog.list_docs)"
                      class="btn-green px-4 py-2 uppercase w-full text-sm flex space-x-3 items-center"
                    >
                      <span class="mx-auto"> documents </span>
                    </button>
                    <button
                      class="btn-white px-4 py-2 md:py-1 text-sm md:text-sm uppercase w-fit sm:w-auto sm:justify-center items-center"
                      @click="handleExport(prog)"
                    >
                      Exportez CSV
                    </button>
                  </div>
                  <div class="flex flex-col text-right">
                    <span class="text-promy-gray-700 text-sm"
                      >Chiffre d'affaire</span
                    >
                    <span class="text-xl text-promy-gray-700 font-extrabold">{{
                      prog.chiffre_affaire | formatEUR(2)
                    }}</span>
                    <span class="text-promy-gray-400 text-sm">
                      {{ prog.chiffre_affaire_hors_taxe | formatEUR }}
                      Hors taxe
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </accordion-item>
      </accordion>
    </div>
    <div
      v-else-if="!isFetching && activitiesList === 0"
      class="flex flex-col items-center w-full mx-auto"
    >
      <img
        src="/images/programme/aucune_offre.png"
        class="w-1/5 xs-ws:w-1/4 lg:w-1/3 sm:w-3/4 my-8"
      />

      <p
        class="font-main text-xl font-bold text-center px-2 text-promy-gray-300 md:text-xl"
      >
        Vous n'avez pas encore ajouté un programme dans Mon Activité
      </p>
    </div>
  </div>
</template>

<script>
import HistoriqueSortBy from './HistoriqueSortBy.vue'
import ModalListFile from './ModalListFile.vue'
import { exportCsv } from '@/utilities/exportCsvOperations'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    Carousel,
    Slide,
    HistoriqueSortBy,
    ModalListFile,
  },
  data() {
    return {
      sortKey: '',
      sortOrder: '',
      isFetching: false,
      activitiesList: [],
      active_accordion: -1,
      sortColumns: {
        adresse: 'adresse',
        nombre_lots: 'nombre_lots',
        date_depot: 'date_depot',
        date_fin_commercialisation: 'date_fin_commercialisation',
        prix_moy_m2_nbre_lots: 'prix_moy_m2_nbre_lots',
        chiffre_affaire: 'chiffre_affaire',
      },
      resetSortIcon: '',
      showModal: false,
      current_docs_modal: [],
      current_title_modal: 'Documents',
      current_action_title_modal: 'Téléchargement',
    }
  },
  created() {
    this.getActivities()
  },
  methods: {
    getActivities(sortBy = 'created_at', sortAsc = 'desc') {
      if (this.$route.params.id === undefined) return

      this.isFetching = true
      this.$http
        .get('admin/team/mon-activite/' + this.$route.params.id, {
          params: {
            sortBy: sortBy,
            sortAsc: sortAsc,
          },
        })
        .then((response) => {
          this.activitiesList = response.data.data
          this.isFetching = false
        })
    },
    activingAccordion(idx) {
      this.active_accordion = this.active_accordion === idx ? -1 : idx
    },
    handleExport(prog) {
      exportCsv([prog])
    },
    setModalInfos(docs) {
      this.showModal = true
      this.current_docs_modal = docs
    },
    sortList({ sortBy, sortAsc }) {
      this.resetSortIcon = sortBy
      this.sortBy = sortBy
      this.sortAsc = sortAsc
      this.resetHistoriques(sortBy, sortAsc)
    },
    resetHistoriques(sortBy, sortAsc) {
      this.activitiesList = []
      this.active_accordion = -1
      this.getActivities(sortBy, sortAsc)
    },
  },
}
</script>
<style scoped>
.rowGap35 {
  row-gap: 35px;
}

.rowGap14 {
  row-gap: 24px;
}
</style>
